import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function Footer({ setDiscoverMenuItem }: any) {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const { t, lang } = useTranslation("common");

  const social = [
    {
      logo: "facebook",
      link: "https://www.facebook.com/bidhouseclub",
    },
    {
      logo: "instagram",
      link: "https://www.instagram.com/bidhouseclub/",
    },
    {
      logo: "discord",
      link: "https://discord.com/invite/bidhouseclub",
    },
    {
      logo: "twitter",
      link: "https://twitter.com/bidhouseclub",
    },
    {
      logo: "telegram",
      link: "https://t.me/BidHouseClub",
    },
  ];
  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem(t("menu.contact"));
    }
  }, [inView]);

  return (
    <section
      ref={ref}
      id={t("menu.contact")}
      className={`section ${!inView && "opacity-0"}`}
    >
      <div
        className={`w-full px-4 flex flex-col items-center ${
          !inView && "opacity-0"
        } ${inView && "opacity-animation-long"}`}
        style={{
          transition: "all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          animationDuration: "1s",
        }}
      >
        <h1
          className={`xs:font-semibold md:font-bold mb-12 font-[ThunderBold]`}
        >
          {t("footer.fly")}
        </h1>
        <p className="max-w-[324px] text-center mb-[72px] text-white">
          {t("footer.description")}
        </p>
        <div className="flex items-center justify-between max-w-[500px] w-full">
          {social.map(
            (socialItem: { logo: string; link: string }, index: number) => {
              return (
                <a
                  key={index}
                  href={socialItem.link}
                  target="_blank"
                  className="w-10 md:w-[30px] h-10 md:h-[30px] relative"
                >
                  <Image
                    src={`/social/${socialItem.logo}.svg`}
                    alt={`social - ${socialItem.logo}`}
                    fill
                    className="filter"
                  />
                </a>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
}
